import { customElement, inject } from 'aurelia-framework';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer)
@customElement('lot-intervention-selection')
export class LanguageSwitchDropdown {

    lotInterventionName;

    eventListeners = [];

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Handles bind event
     *
     * @returns {Promise<any[]>}
     */
    bind() {
        return this.fetchFromAuthenticatedUser();
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeEventListeners();
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `lot-intervention-selected` event
        this.eventListeners.push(
            this.appContainer.eventAggregator.subscribe('lot-intervention-selected', () => {
                this.fetchFromAuthenticatedUser();
            }),
        );
    }

    /**
     * Fetches data from authenticated user
     */
    fetchFromAuthenticatedUser() {
        this.lotInterventionName = this.appContainer.authenticatedUser.getSelectedLotInterventionDisplayName();
    }

    /**
     * Redirects to the lot/intervention selection page
     *
     * @return {*}
     */
    selectLotIntervention() {
        return this.appContainer.router.navigateToRoute('concessions.lot-intervention-selection.index');
    }
}
